<template>
  <v-card
    ref="form"
    tile
    elevation="0"
    color="white"
    class="pt-6 text-center mx-auto justify-center align-center"
  >
    <div>
      <img :src="require('@/assets/img/Gruppe 1013.png')" width="40%" />
    </div>

    <v-sheet
      color="grey lighten-4"
      class="text-left mt-12 py-10 px-5"
      width="100%"
      height="100%"
      style="border-top-left-radius: 50px; border-top-right-radius: 50px;"
    >
      <v-card-text>
        <v-row no-gutters>
          <v-col cols="12">
            <v-card-title
                class="mb-10 text-center font-weight-bold black--text justify-center"
            >
              {{ $t('delivery.lang_createAccount') }}
            </v-card-title>
          </v-col>

          <v-form v-model="valid" lazy-validation ref="registerForm" >

            <v-col cols="12">
              <small class="text-left grey--text caption">{{ $t('delivery.lang_Salutation') }}</small>
              <v-select
                  ref="salutation"
                  v-model="salutation" item-text="name" item-value="value"
                  :items="salutationItems"
                  :placeholder="$t('delivery.lang_Salutation')"
                  background-color="white"
                  filled
                  dense
                  rounded
                  required
                  class="rounded-lg"
              ></v-select>
            </v-col>

            <v-col cols="12" style="margin-top: -12px;">
              <small class="text-left grey--text caption">{{this.$t('delivery.lang_firstName')}}<span class="error--text ">*</span></small>
              <v-text-field
                  ref="firstName"
                  v-model="firstName"
                  placeholder="John"
                  background-color="white"
                  filled
                  :rules="[rules.required,]"
                  @keypress="checkPattern"
                  dense
                  rounded
                  required
                  class="rounded-lg"
              ></v-text-field>
            </v-col>

            <v-col cols="12" style="margin-top: -12px;">
              <small class="text-left grey--text caption">{{this.$t('delivery.lang_lastName')}}<span class="error--text ">*</span></small>
              <v-text-field
                  ref="lastName"
                  v-model="lastName"
                  placeholder="Doe"
                  background-color="white"
                  filled
                  :rules="[rules.required,]"
                  @keypress="checkPattern"
                  dense
                  rounded
                  required
                  class="rounded-lg"
              ></v-text-field>
            </v-col>

            <v-col cols="12" style="margin-top: -12px;">
              <small class="text-left grey--text caption">{{$t("delivery.lang_phoneNumber")}}<span class="error--text ">*</span></small>
              <v-text-field
                  ref="mobile"
                  v-model="mobile"
                  placeholder="+49 987 532 466 154"
                  type="number"
                  background-color="white"
                  filled :rules="[rules.required,]"
                  dense
                  rounded
                  required
                  class="rounded-lg"
              ></v-text-field>
            </v-col>

            <v-col cols="12" style="margin-top: -12px;">
              <small class="text-left grey--text caption">E-mail<span class="error--text">*</span></small>
              <v-text-field
                  ref="email"
                  v-model="email"
                  placeholder="Johedoe@cmail.com"
                  type="email"
                  background-color="white"
                  filled :rules="[rules.required,rules.email,]"
                  dense
                  rounded
                  required
                  class="rounded-lg" autocomplete="off"
              ></v-text-field>
            </v-col>

            <v-col cols="12" style="margin-top: -12px;">
              <small class="text-left grey--text caption">{{this.$t('delivery.lang_password')}}<span class="error--text ">*</span></small>
              <v-text-field
                  ref="password" :rules="[rules.required,]"
                  v-model="password"
                  placeholder="**********"
                  :type="(showPassword)?'text':'password'"
                  :append-icon="(showPassword)?'mdi-eye':'mdi-eye-off'"
                  background-color="white"
                  filled
                  dense
                  rounded
                  required autocomplete="off"
                  class="rounded-lg" @click:append="showPassword=!showPassword"
                  :hint="$t('delivery.lang_minChars8')"
              ></v-text-field>
            </v-col>

            <v-col cols="12" style="margin-top: -12px;">
              <small class="text-left grey--text caption">{{$t('delivery.lang_confirmPassword')}}<span class="error--text ">*</span></small>
              <v-text-field
                  ref="password_again"
                  v-model="password_again"
                  placeholder="**********"
                  :type="(showResetPassword)?'text':'password'"
                  :append-icon="(showResetPassword)?'mdi-eye':'mdi-eye-off'"
                  background-color="white"
                  filled :rules="[rules.required,v=>password_again===password|| $t('delivery.lang_pleaseRetypeThePasswordAgain')]"
                  dense
                  rounded
                  required autocomplete="off"
                  class="rounded-lg" @click:append="showResetPassword=!showResetPassword"
              ></v-text-field>
            </v-col>

            <!-- <v-col cols="12" style="margin-top: -12px;">
              <small class="text-left grey--text caption">{{$t("delivery.lang_street")}}<span class="error--text ">*</span></small>
              <v-text-field
                  ref="street"
                  v-model="street"
                  placeholder="kiel"
                  background-color="white"
                  filled
                  :rules="[rules.required,]"
                  @keypress="checkPattern"
                  dense
                  rounded
                  required
                  class="rounded-lg"
              ></v-text-field>
            </v-col>

            <v-col cols="12" style="margin-top: -12px;">
              <small class="text-left grey--text caption">{{$t("delivery.lang_streetNo")}}<span class="error--text ">*</span></small>
              <v-text-field
                  ref="streetNumber"
                  v-model="streetNumber"
                  placeholder="64"
                  background-color="white"
                  filled
                  :rules="[rules.required,]"
                  dense
                  rounded
                  required
                  class="rounded-lg"
              ></v-text-field>
            </v-col>

            <v-col cols="12" style="margin-top: -12px;">
              <small class="text-left grey--text caption">{{ $t("delivery.lang_zipCode") }}<span class="error--text ">*</span></small>
              <v-text-field
                  ref="zip"
                  v-model="zip"
                  placeholder="79938"
                  background-color="white"
                  filled
                  :rules="[rules.required,]"
                  dense
                  rounded
                  required
                  class="rounded-lg"
              ></v-text-field>
            </v-col>

            <v-col cols="12" style="margin-top: -12px;">
              <small class="text-left grey--text caption">{{ $t('delivery.lang_city') }}<span class="error--text ">*</span></small>
              <v-text-field
                  ref="city"
                  v-model="city"
                  placeholder="berlin"
                  background-color="white"
                  filled
                  :rules="[rules.required,]"
                  @keypress="checkPattern"
                  dense
                  rounded
                  required
                  class="rounded-lg"
              ></v-text-field>
            </v-col> -->
          </v-form>

          <v-col cols="12">
            <div class="my-5">
              <v-btn
                  large
                  depressed
                  block
                  color="primary"
                  class="text-capitalize rounded-lg"
                  @click="register" :disabled="!valid || loading"
              >
                {{$t('delivery.lang_Register')}}
              </v-btn>
            </div>

            <div class="caption text-center" @click="$emit('show','login')">
              <span>{{$t('delivery.lang_alreadyRegistered')}}</span>
              <small
                  style="cursor: pointer;"
                  class="caption primary--text"

              >
                {{$t('delivery.lang_login')}}
              </small>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-sheet>
  </v-card>
</template>

<script>
/*import plugins*/
//import { createNamespacedHelpers } from "vuex";
import validation from "../../mixins/validation";

export default {
  name: "Register",
  mixins:[validation],
  methods: {
    register() {

      if(!this.$refs.registerForm.validate())
        return ;

      this.overlay = true;
      this.$store.commit("Loader/changeStatus", true, { root: true }); //start global loading


        this.axios.post(`/localbee/v2/auth/register/`, {
              // street: this.street,
              // streetNumber: this.streetNumber,
              // zip: this.zip,
              // city: this.city,
              mobile: this.mobile,
              firstname: this.firstName,
              lastname: this.lastName,
              email: this.email,
              password: this.password,
              password_again: this.password_again,
              salutation:this.salutation
              /*time: window.store.state.dateTime.date,
              date: window.store.state.dateTime.time,
              tables: window.store.state.tables.tables,
              items: window.store.state.items.selectedItems,*/
            })
            .then(res=> {
              if(res && res.status===201){
                this.$swal({
                  title: this.$t('delivery.lang_accountcreated'),
                  icon: "success",
                  text: this.$t('delivery.lang_emailValidation'),
                });
                this.$emit('show','success')
              }
            })
            .catch(err=> {
              if(err && err.status===400){
                this.$swal({
                  title: this.$t('delivery.lang_error'),
                  icon: "error",
                  text: err.data.error.message,
                });
              }else if(err && err.status===422){
                this.$swal({
                  title: this.$t('delivery.lang_error'),
                  icon: "error",
                  text: err.data.message,
                });
              }else{
                this.$swal({
                  title: this.$t('delivery.lang_error'),
                  icon: "error",
                  text: this.$t('delivery.lang_anErrorOccuredWhileRegisteringPleaseTryAgainWithValidData'),
                });
              }
            })
            .finally(()=> {
              this.$store.commit("Loader/changeStatus", false, { root: true }); //stop global loading
            });
    },
  },

  computed: {
    step: {
      get() {
        return this.$store.getters["Config/authDlg"];
      },
      set(val) {
        this.$store.commit("Config/setAuthDlg", val);
      },
    },
  },

  data(){
    return{
      salutationItems: [
          {
            name:this.$t('delivery.lang_woman'),
            value:'w',
          },
          {
            name:this.$t('delivery.lang_mr'),
            value:'m',
          },
          {
            name:this.$t('delivery.lang_family'),
            value:'f',
          },
          {
            name:this.$t('delivery.lang_miscellaneous'),
            value:'d',
          },
      ],
      showPassword:false,
      showResetPassword:false,
      countries: [
        "Afghanistan",
        "Albania",
        "Algeria",
        "Andorra",
        "Angola",
        "Anguilla",
        "Antigua &amp; Barbuda",
        "Argentina",
        "Armenia",
        "Aruba",
        "Australia",
        "Austria",
        "Azerbaijan",
        "Bahamas",
        "Bahrain",
        "Bangladesh",
        "Barbados",
        "Belarus",
        "Belgium",
        "Belize",
        "Benin",
        "Bermuda",
        "Bhutan",
        "Bolivia",
        "Bosnia &amp; Herzegovina",
        "Botswana",
        "Brazil",
        "British Virgin Islands",
        "Brunei",
        "Bulgaria",
        "Burkina Faso",
        "Burundi",
        "Cambodia",
        "Cameroon",
        "Cape Verde",
        "Cayman Islands",
        "Chad",
        "Chile",
        "China",
        "Colombia",
        "Congo",
        "Cook Islands",
        "Costa Rica",
        "Cote D Ivoire",
        "Croatia",
        "Cruise Ship",
        "Cuba",
        "Cyprus",
        "Czech Republic",
        "Denmark",
        "Djibouti",
        "Dominica",
        "Dominican Republic",
        "Ecuador",
        "Egypt",
        "El Salvador",
        "Equatorial Guinea",
        "Estonia",
        "Ethiopia",
        "Falkland Islands",
        "Faroe Islands",
        "Fiji",
        "Finland",
        "France",
        "French Polynesia",
        "French West Indies",
        "Gabon",
        "Gambia",
        "Georgia",
        "Germany",
        "Ghana",
        "Gibraltar",
        "Greece",
        "Greenland",
        "Grenada",
        "Guam",
        "Guatemala",
        "Guernsey",
        "Guinea",
        "Guinea Bissau",
        "Guyana",
        "Haiti",
        "Honduras",
        "Hong Kong",
        "Hungary",
        "Iceland",
        "India",
        "Indonesia",
        "Iran",
        "Iraq",
        "Ireland",
        "Isle of Man",
        "Israel",
        "Italy",
        "Jamaica",
        "Japan",
        "Jersey",
        "Jordan",
        "Kazakhstan",
        "Kenya",
        "Kuwait",
        "Kyrgyz Republic",
        "Laos",
        "Latvia",
        "Lebanon",
        "Lesotho",
        "Liberia",
        "Libya",
        "Liechtenstein",
        "Lithuania",
        "Luxembourg",
        "Macau",
        "Macedonia",
        "Madagascar",
        "Malawi",
        "Malaysia",
        "Maldives",
        "Mali",
        "Malta",
        "Mauritania",
        "Mauritius",
        "Mexico",
        "Moldova",
        "Monaco",
        "Mongolia",
        "Montenegro",
        "Montserrat",
        "Morocco",
        "Mozambique",
        "Namibia",
        "Nepal",
        "Netherlands",
        "Netherlands Antilles",
        "New Caledonia",
        "New Zealand",
        "Nicaragua",
        "Niger",
        "Nigeria",
        "Norway",
        "Oman",
        "Pakistan",
        "Palestine",
        "Panama",
        "Papua New Guinea",
        "Paraguay",
        "Peru",
        "Philippines",
        "Poland",
        "Portugal",
        "Puerto Rico",
        "Qatar",
        "Reunion",
        "Romania",
        "Russia",
        "Rwanda",
        "Saint Pierre &amp; Miquelon",
        "Samoa",
        "San Marino",
        "Satellite",
        "Saudi Arabia",
        "Senegal",
        "Serbia",
        "Seychelles",
        "Sierra Leone",
        "Singapore",
        "Slovakia",
        "Slovenia",
        "South Africa",
        "South Korea",
        "Spain",
        "Sri Lanka",
        "St Kitts &amp; Nevis",
        "St Lucia",
        "St Vincent",
        "St. Lucia",
        "Sudan",
        "Suriname",
        "Swaziland",
        "Sweden",
        "Switzerland",
        "Syria",
        "Taiwan",
        "Tajikistan",
        "Tanzania",
        "Thailand",
        `Timor L'Este`,
        "Togo",
        "Tonga",
        "Trinidad &amp; Tobago",
        "Tunisia",
        "Turkey",
        "Turkmenistan",
        "Turks &amp; Caicos",
        "Uganda",
        "Ukraine",
        "United Arab Emirates",
        "United Kingdom",
        "United States",
        "Uruguay",
        "Uzbekistan",
        "Venezuela",
        "Vietnam",
        "Virgin Islands (US)",
        "Yemen",
        "Zambia",
        "Zimbabwe",
      ],
      salutation:"",
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      password_again: "",
      street: "",
      streetNumber: "",
      zip: "",
      city: "",
      mobile: "",
      valid:false,
      loading:false,
    }
  },

};
</script>
